//
// General: Mixins
//

// Select2 Variant
@mixin select2-variant($name, $color) {
  .select2-#{$name} {

    .select2-container--default &,
    .select2-container--default {
      &.select2-dropdown,
      .select2-dropdown,
      .select2-search--inline {
        .select2-search__field {
          &:focus {
            border: $input-border-width solid lighten($color, 25%);
          }
        }
      }

      .select2-results__option--highlighted {
        background-color: $color;
        color: color-yiq($color);

        &[aria-selected] {
          &,
          &:hover {
            background-color: darken($color, 3%);
            color: color-yiq(darken($color, 3%));
          }
        }
      }

      //Multiple select
      & {
        .select2-selection--multiple {
          &:focus {
            border-color: lighten($color, 25%);
          }

          .select2-selection__choice {
            background-color: $color;
            border-color: darken($color, 5%);
            color: color-yiq($color);
          }

          .select2-selection__choice__remove {
            color: rgba(color-yiq($color), 0.7);

            &:hover {
              color: color-yiq($color);
            }
          }
        }

        &.select2-container--focus .select2-selection--multiple {
          border-color: lighten($color, 25%);
        }
      }
    }
  }
}
